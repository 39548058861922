<template>
    <v-container class="panel-wrapper pa-0" grid-list-lg fluid>
        <v-card class="elevation-0">
            <v-card-text>
                <div :style="{color:billingMsg.color}">
                    <i class="fa fa-circle" style="font-size: 6px;position: relative;bottom: 3px;"></i>
                    <span style="font-size: 20px;">&nbsp;{{clientStatus}}</span>
                </div>
                <div class="mb-3 mt-2" :style="{color: $vuetify.theme.primary}">
                    <v-icon class="ma-0" color="primary lighten-1" size="18" style="position: relative;top: -1px;">
                        info
                    </v-icon>
                    <span style="font-size: 16px;">&nbsp;{{billingMsg.text}}</span>
                </div>
                <v-divider></v-divider>
                <BillingInfoForm ref="billingform" @submit="autoVerifyResult"></BillingInfoForm>
                <div style="text-align: right;">
                    <v-btn @click="updateBillingInfo" :loading="loading" class="text-none" color="primary">Update
                    </v-btn>
                </div>
            </v-card-text>
        </v-card>
        <pending-account-notice ref="pending"></pending-account-notice>
    </v-container>
</template>

<script>
    import {formatDate} from '@/module/utils/date'
    import rules from '@/module/rules/index.js'
    import BillingInfoForm from '@/components/BillingInfo/BillingInfoForm.vue'
    import {mapState, mapGetters} from 'vuex'
    import PendingAccountNotice from '@/components/BillingInfo/PendingAccountNotice'


    export default {
        name: "BillingInfo",
        components: {
            BillingInfoForm,
            PendingAccountNotice
        },
        data() {
            return {
                loading: false,
                formData: {},
                rules
            }
        },
        computed: {
            clientStatus() {
                if (!this.checkInfoCompleted()) {
                    return 'Pending'
                } else if (this.clientInfo.status === 'Good') {
                    return 'Active'
                } else {
                    return 'Processing'
                }
            },
            billingMsg() {
                const map = {
                    Active: {
                        color: '#5BD560',
                        text: 'Your billing account is active. You can renew your app to extend the due date at \'My Applications\'.'
                    },
                    Processing: {
                        color: this.$vuetify.theme.warning,
                        text: 'Your billing account is under verifying.'
                    },
                    Pending: {
                        color: this.$vuetify.theme.primary,
                        text: 'Please update your billing information to activate paid account.'
                    }
                }
                return map[this.clientStatus]
            },
            ...mapGetters('client', ['checkInfoCompleted']),
            ...mapState('client', ['clientInfo'])
        },
        methods: {
            formatDate,
            updateBillingInfo() {
                this.loading = true
                const formerStatus = this.clientInfo.status
                this.$refs.billingform.submit().then((res) => {
                    this.loading = false
                    if (res.status === 'Uncertain' || res.status === 'Unverified') {
                        this.$refs.pending.open({isGood: false})
                    } else if (res.status === 'Good' && formerStatus !== 'Good') {
                        this.$refs.pending.open({isGood: true})
                    } else {
                        this.$message.success('Your billing information was updated successfully!')
                    }
                })
            },
        },
        created() {
            this.formData = Object.assign({}, this.clientInfo)
        }
    }
</script>
