var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    {
      staticClass: "panel-wrapper pa-0",
      attrs: { "grid-list-lg": "", fluid: "" }
    },
    [
      _c(
        "v-card",
        { staticClass: "elevation-0" },
        [
          _c(
            "v-card-text",
            [
              _c("div", { style: { color: _vm.billingMsg.color } }, [
                _c("i", {
                  staticClass: "fa fa-circle",
                  staticStyle: {
                    "font-size": "6px",
                    position: "relative",
                    bottom: "3px"
                  }
                }),
                _c("span", { staticStyle: { "font-size": "20px" } }, [
                  _vm._v(" " + _vm._s(_vm.clientStatus))
                ])
              ]),
              _c(
                "div",
                {
                  staticClass: "mb-3 mt-2",
                  style: { color: _vm.$vuetify.theme.primary }
                },
                [
                  _c(
                    "v-icon",
                    {
                      staticClass: "ma-0",
                      staticStyle: { position: "relative", top: "-1px" },
                      attrs: { color: "primary lighten-1", size: "18" }
                    },
                    [_vm._v("\n                    info\n                ")]
                  ),
                  _c("span", { staticStyle: { "font-size": "16px" } }, [
                    _vm._v(" " + _vm._s(_vm.billingMsg.text))
                  ])
                ],
                1
              ),
              _c("v-divider"),
              _c("BillingInfoForm", {
                ref: "billingform",
                on: { submit: _vm.autoVerifyResult }
              }),
              _c(
                "div",
                { staticStyle: { "text-align": "right" } },
                [
                  _c(
                    "v-btn",
                    {
                      staticClass: "text-none",
                      attrs: { loading: _vm.loading, color: "primary" },
                      on: { click: _vm.updateBillingInfo }
                    },
                    [_vm._v("Update\n                ")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c("pending-account-notice", { ref: "pending" })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }