<template>
    <v-dialog width="600" v-model="dialog" :persistent="isCheck" lazy>
        <v-card style="border-radius: 10px;">
            <v-card-text>
                <v-layot column>
                    <v-layot column>
                        <v-flex pt-4>
                            <img style="margin: 0 auto;width: 200px;display: block;height: 137px;"
                                 :src="isGood?goodImg:uncertainImg">
                        </v-flex>
                        <v-flex pb-4 style="text-align: center;" pt-4>
                            <span class="fs-4" :style="{color:isGood?'#18b23d':'#ffa700'}">{{isGood?'Your billing account is active now. Thank you for your business.':'Your billing information is under verified, it may take up to 2 hours. Thanks for your patience.'}}</span>
                        </v-flex>
                    </v-layot>
                    <v-flex pb-3 style="text-align: center">
                        <v-btn class="text-none" dark v-if="isCheck" color="#ffa700" to="/applications"> Check My
                            Applications
                        </v-btn>
                    </v-flex>
                </v-layot>
            </v-card-text>
        </v-card>
    </v-dialog>
</template>


<script>
    import loadingMixin from '@/module/mixins/loading.mixin.js'
    import goodImg from '@/assets/images/client_status/good.png'
    import uncertainImg from '@/assets/images/client_status/uncertain.png'


    export default {
        name: 'PendingAccountNotice',
        mixins: [loadingMixin],
        props: ['isCheck'],
        data() {
            return {
                goodImg,
                uncertainImg,
                isGood: false
            }
        },
        methods: {
            open({isGood}) {
                this.isGood = isGood || false
                this.dialog = true
            }
        }
    }


</script>